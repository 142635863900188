import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const TopNav = ({ colorInvert = false }) => {
  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width="100%">
      <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} flexGrow={1}>
        <Link
          underline="none"
          component="a"
          href="/virtual-tour"
          color={colorInvert ? 'common.white' : 'text.primary'}
          sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}
        >
          <Box padding={0.5} borderRadius={1} bgcolor={'primary.main'} marginLeft={1}>
            <Typography
              variant={'caption'}
              sx={{ color: 'common.white', lineHeight: 1 }}
            >
              Virtual Tour
            </Typography>
          </Box>
        </Link>
        <Link
          href="https://www.facebook.com/p/National-Public-School-Yeshwanthpur-100084410342747/"
          color="text.primary"
          target="_blank"
          rel="noopener"
          sx={{ marginLeft: 2 }}
        >
          <FacebookIcon />
        </Link>
        <Link
          href="https://www.instagram.com/nps_ypr/"
          color="text.primary"
          target="_blank"
          rel="noopener"
          sx={{ marginLeft: 2 }}
        >
          <InstagramIcon />
        </Link>
      </Box>
    </Box>
  );
};

TopNav.propTypes = {
  colorInvert: PropTypes.bool,
};

export default TopNav;
